import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { deleteAccount } from "../actions/accountActions";

class DeleteAccountDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleSubmit(event) {
    this.props.deleteAccountAction(this.props.login_token, this.props.account);    
    this.setState({ open: false });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Button size="small"  color="secondary" aria-label="remove" variant="outlined" onClick={this.handleClickOpen}>
          Remove account
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} fullWidth={true} maxWidth="sm" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Warning</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography gutterBottom>
                The account and all associated resources will be removed from the system. This action is permanent.  
              </Typography>            
              <Typography gutterBottom>
                <b>Organization:</b> {this.props.organization.name}
              </Typography>
              <Typography gutterBottom>
                <b>Account:</b> { this.props.account.account_name } ({this.props.account.account_id})
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="success" variant="contained">
              Cancel
          </Button>
            <Button onClick={this.handleSubmit} color="secondary" variant="contained">
              Delete account
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { session_id, login_token } = state;
  return {
    session_id: session_id,
    login_token: login_token
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    deleteAccountAction: deleteAccount
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteAccountDialog);