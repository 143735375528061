import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSession, signin, verifyLogin } from "./actions/loginActions";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import LoginViewHeader from "./views/loginviewheader";

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';


import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Grid from '@material-ui/core/Grid';

import { Typography } from '@material-ui/core';

let theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Georgia',
      'Cambria',
      'Times New Roman',
      'Times',
      'serif'
    ].join(','),
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      margin: 10,
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    padding: '48px 36px 0',
    background: '#eaeff1',
  },
};

class Paperbase extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };

    this.onLoginResponse = this.onLoginResponse.bind(this);
  }

  componentDidMount() {
    this.props.getSessionAction();
    this.props.verifyLoginAction(this.props.login_token);
  }

  async onLoginResponse(resp) {
    console.log("on login response", resp);

    if (resp.status === 'PARTIALLY_AUTHENTICATED') {
      this.props.signinAction(resp);
    }
  }

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes } = this.props;
    if (this.props.login_token) {
      return (
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <nav className={classes.drawer}>
              <Hidden smUp implementation="js">
                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  variant="temporary"
                  open={this.state.mobileOpen}
                  onClose={this.handleDrawerToggle}
                />
              </Hidden>
              <Hidden xsDown implementation="css">
                <Navigator PaperProps={{ style: { width: drawerWidth } }} />
              </Hidden>
            </nav>
            <div className={classes.appContent}>
              <Header onDrawerToggle={this.handleDrawerToggle} />
              <main className={classes.mainContent}>
                <Content />
              </main>
            </div>
          </div>
        </ThemeProvider>
      );
    }
    else if (this.props.session_id) {
      return (
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            <div className={classes.appContent}>
              <LoginViewHeader onDrawerToggle={this.handleDrawerToggle} onLoginResponse={this.onLoginResponse} />
              <main className={classes.mainContent}>
               <React.Fragment>
                  <Typography component="p" align={'left'}>
                    Administrators should not create permanent user accounts for users of cloud services (except root user 
                    which should be carefully guarded and used very rarely). Access should be granted through an automated 
                    role elevation that goes through an optional approval by one or more persons. Alerchimp help achieving 
                    this without an in-house developed service. Alerchimp's own actions can be logged and audited which 
                    Alertchimp service can not temper.
                  </Typography>
                  <Typography component="p">
                    With Alertchimp you can securely access your AWS accounts and people can request for short term access to any role.
                  </Typography>
                </React.Fragment>   
                <div>
                  <p>~~~~~~~~~~~~</p>
                </div>
                <Grid container spacing={3}>                  
                  <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={4}>
                        <Typography variant="h5" component="h3">
                          Zero defult permission
                        </Typography>
                        <Typography component="p">
                          On demand easy elevation with or without human approval                            
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5" component="h3">
                          As a service
                        </Typography>
                        <Typography component="p">
                          Just start using as a service at ugly.alertchimp.com.
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="h5" component="h3">
                          In your infrastructure
                        </Typography>
                        <Typography component="p">
                          AlertChimp can be installed in your own AWS account.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={3}></Grid>
                    <Grid item xs={12}  alignItems="right">
                      <p>A really early demo video (best in full screen in HD)</p>
                      <iframe width="600" height="400" src="https://www.youtube.com/embed/Zg_GMiIZOnk" frameborder="1" allow="fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Grid>
                  </Grid>
              </main>
            </div>
          </div>
        </ThemeProvider>
      );
    }
    else {
      return "Loading....";
    }
  }
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  const { session_id, login_token } = state;
  return {
    session_id: session_id,
    login_token: login_token
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    getSessionAction: getSession,
    signinAction: signin,
    verifyLoginAction: verifyLogin
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Paperbase));