import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveRole } from "../actions/accountActions";

class NewRoleFormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: props.organization && props.organization.id,
      account_id: props.account && props.account.account_id,
      org: props.organization,
      account: props.account,
      open: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleChange(field, value) {
    this.setState({ [field]: value });
    
    if(field === 'role_name') {      
      this.setState({"role_arn": `arn:aws:iam::${this.state.account_id}:role/${value}`})
    }    

  }

  handleSubmit(event) {
    this.props.saveRoleAction(this.props.login_token, this.state);
    event.preventDefault();
    this.setState({ open: false });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Tooltip title="Add new role">
        <Button size="small"  color="primary" aria-label="Add" onClick={this.handleClickOpen}>
          <AddIcon />
        </Button>
        </Tooltip>
        <Dialog open={this.state.open} onClose={this.handleClose} fullWidth={true} maxWidth="sm" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add a role</DialogTitle>
          <DialogContent>
            <DialogContentText>            
              <Typography gutterBottom>
                <b>Organization:</b> {this.state.org.name}
              </Typography>
              <Typography gutterBottom>
                <b>Account:</b> { this.state.account.account_name } ({this.state.account_id})
              </Typography>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="role_name"
              label="Role name"
              type="text"
              fullWidth
              onChange={(event) => this.handleChange("role_name", event.target.value)}
            />
            <TextField
              disabled              
              margin="dense"
              id="role_arn"
              label="Role ARN"
              type="text"
              fullWidth
              value={this.state.role_arn}              
            />
            <FormControlLabel control={<Checkbox onChange={event => this.handleChange("auto_approved", event.target.checked)} />} label="Auto approved to organization members" />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
          </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Submit
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { session_id, login_token } = state;
  return {
    session_id: session_id,
    login_token: login_token
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    saveRoleAction: saveRole
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(NewRoleFormDialog);