//import { loadAccounts } from "./accountActions";

import {
    ALERTCHIMP_API_BASE_URL,
    SET_SESSION_ID,
    SET_LOGIN_TOKEN,
    SET_LOGIN_PROFILE,
    LOGOUT_ACCOUNT
} from "./constants";

import { loadMyOrganizations } from "./accountActions";

const onReceiveSessionId = (session_id) => {
    return {
        type: SET_SESSION_ID,
        payload: session_id
    };
}

const onReceiveLoginToken = (login_token) => {
    return {
        type: SET_LOGIN_TOKEN,
        payload: login_token
    };
}

export const onLogoutAccount = () => {
    return {
        type: LOGOUT_ACCOUNT
    }
}

export const onReceiveVerificationResult = (login_profile) => {
    return { 
        type: SET_LOGIN_PROFILE,
        payload: login_profile
    };
}


export const logoutAccount = () => {
    return dispatch => {
        sessionStorage.removeItem("login_token");
        dispatch(onLogoutAccount());
    }
}

export function verifyLogin(login_token) {
    return async (dispatch) => {
        console.log("Verify login accounts", login_token);
        if (login_token) {
            let accounts = await fetch("/api/v1/verify/", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': login_token["token"]
                }
            }).then(r => {
                if (r.ok) {
                    r.json().then((loginProfile) => {
                        dispatch(onReceiveVerificationResult(loginProfile));                            
                    })
                } else {
                    dispatch(logoutAccount());
                }
            });
        }
    }
}

export const getSession = () => {
    return dispatch => {
        let session_id = sessionStorage.getItem('session_id');

        if (session_id) {
            console.log("Loaded session from session storage: ", session_id);
            dispatch(onReceiveSessionId(session_id));
        }
        else {
            fetch("/api/v1/session")
                .then(res => res.text())
                .then(result => {
                    sessionStorage.setItem('session_id', result);
                    dispatch(onReceiveSessionId(result));
                });
        }
    }
};

export const signin = (resp) => {
    return async (dispatch) => {
        console.log("Signin Action");
        if (resp.status === 'PARTIALLY_AUTHENTICATED') {
            var token = await fetch("/api/v1/login", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(resp)
            }).then(r => r.json());
            console.log("Setting new login token", token);
            sessionStorage.setItem('login_token', JSON.stringify(token));

            dispatch(onReceiveLoginToken(token));
            dispatch(loadMyOrganizations(token));
        }
    }
};