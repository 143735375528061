
import {
    CHANGE_CURRENT_ORGANIZATION
} from "./constants";

import { showMessage } from "../actions/messageActions";

const onChangeNavigationView = (org) => {
    return {
        type: CHANGE_CURRENT_ORGANIZATION,
        payload: org
    };
}

export const changeCurrentOrganization = (org) => {
    return dispatch => {
        console.log("Changed org to ", org);
        dispatch(onChangeNavigationView(org));
    }
}

export const saveOrganizationMembership = (login_token, membership) => {
    return async (dispatch) => {
        let url = "/api/v1/membership";

        let result = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            },
            body: JSON.stringify(membership)
        }).then(r => r.json());

        console.log("Organization membership created", result);
        //dispatch(loadMyOrganizations(login_token, membership));
    }    
}

export const removeOrganizationMembership = (login_token, membership, enqueueSnackbar) => {
    return async (dispatch) => {
        let {org_id, account_id} = membership;
        let url = `/api/v1/membership/${org_id}/${account_id}`;

        let result = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            },
            body: JSON.stringify(membership)
        });
        let msg = "Membership removed";
        let variant = 'info';
        if (result.ok) {
            variant='success';
        }
        else {
            msg = await result.json();
            variant = 'error';            
        }

        if(enqueueSnackbar) {
            console.log("Stacking snack");
            enqueueSnackbar(msg, { variant: variant});
        }
        console.log("Organization membership remove result", result);
        //dispatch(loadMyOrganizations(login_token, membership));
    }    
}

export const deleteOrganization = (login_token, org, enqueueSnackbar) => {
    return async (dispatch) => {        
        let url = `/api/v1/organization/${org.id}/`;

        let result = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        });

        let msg = "Organization removed";
        let variant = 'info';
        if (result.ok) {
            variant='success';
        }
        else {
            msg = await result.json();
            variant = 'error';            
        }

        if(enqueueSnackbar) {
            console.log("Stacking snack");
            enqueueSnackbar(msg, { variant: variant});
        }
        console.log("Organization remove result", result);
    }    
}