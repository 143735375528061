import React from 'react';
import './App.css';
import Theme from "./Paperbase";
import { connect } from 'react-redux';

function App() {

  return (
    <div className="App">
        <Theme/>
    </div>
  );
}

const mapStateToProps = (state) => {
  return state;
}

const mapActionsToProps = (state) => {
  return state;
}

export default connect(mapStateToProps)(App);


//export default connect(App);
// Theme: https://themes.material-ui.com/previews/paperbase/