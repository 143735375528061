import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';

import { approveElevationRequest, denyElevationRequest } from "../actions/elevationActions";

class ElevationActionDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {      
      open: props.open
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDeny = this.handleDeny.bind(this);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleSubmit(event) {
    this.props.approveElevationRequestAction(this.props.login_token, this.props.request);
    this.setState({ open: false });
    event.preventDefault();
  }

  handleDeny(event) {
    this.props.denyElevationRequestAction(this.props.login_token, this.props.request);
    this.setState({ open: false });
    event.preventDefault();
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Button size="small" variant="contained" color="primary" onClick={this.handleClickOpen}>
          Details
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} fullWidth maxWidth="xs" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Elevation request</DialogTitle>
          <DialogContent>
            <DialogContentText>            
            <Typography gutterBottom>
                <b>User ID:</b> {this.props.request.user_accout_id}
              </Typography>
              <Typography gutterBottom>
                <b>AWS Account ID:</b> {this.props.request.account_id}
              </Typography>
              <Typography gutterBottom>
                <b>Role:</b> {this.props.request.role_name}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeny}  variant="contained" color="secondary">
              Deny
          </Button>
            <Button onClick={this.handleSubmit} variant="contained" color="primary">
              Approve
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { session_id, login_token } = state;
  return {
    session_id: session_id,
    login_token: login_token
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    approveElevationRequestAction: approveElevationRequest,
    denyElevationRequestAction: denyElevationRequest
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(ElevationActionDialog));