import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveOrganization } from "../actions/accountActions";

class OrganizationForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {name: props.name};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleClickOpen = this.handleClickOpen.bind(this);
      this.handleClose = this.handleClose.bind(this);
    }
  
    handleChange(event) {
      this.setState({name: event.target.value});
    }
  
    handleSubmit(event) {
      let org = {name: this.state.name};
      this.props.saveOrganizationAction(this.props.login_token, org);
      this.setState({ open: false });
    }
  
    handleClickOpen() {
      this.setState({ open: true });
    }

    handleClose(event) {
      this.setState({ open: false });
    }

    render() {
      return (
          <div>
        <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
        Add new organization
      </Button>
      <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">New organizatiopn</DialogTitle>
        <DialogContent>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Organization name"
              type="text"
              fullWidth
              onChange={this.handleChange}
            />
        </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
          </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Submit
          </Button>
          </DialogActions>
        </Dialog>
        </div>
      );
    }
}

const mapStateToProps = (state) => {
    const { session_id, login_token, current_view } = state;
    return {
      session_id: session_id,
      login_token: login_token,
      current_view: current_view
    };
  }
  
  const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveOrganizationAction: saveOrganization
    }, dispatch);
  }
  
  export default connect(mapStateToProps, mapActionsToProps)(OrganizationForm);