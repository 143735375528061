import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSession, signin } from "./actions/loginActions";

import OrganizationMembershipView from "./views/orgmembershipview";
import ElevationRequestsView from "./views/ElevationRequestsView";
import OrganizationListView from "./OrganizationListView";
import HomeView from "./views/HomeView";
import SettingsView from './views/settingsview';
import AboutView from './views/aboutview';

const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});


class Content extends React.Component {
  constructor(props) {
    super(props);
  }

  getCurrentView() {
    console.log("Navigating to ", this.props.current_view);

    if(this.props.current_view === 'Users') {
      return <OrganizationMembershipView />;
    }
    else if(this.props.current_view === 'Organizations') {
      return <OrganizationListView />;
    }
    else if(this.props.current_view === 'Requests') {
      return <ElevationRequestsView />
    }
    else if(this.props.current_view === 'Settings') {
      return <SettingsView />;
    }
    else if(this.props.current_view === 'About') {
      return <AboutView />;
    }
    else {
      return <HomeView />
    }
  }

  render() {
    if (this.props.login_token) {
      return (

          <div className={this.props.classes.contentWrapper}>
            { this.getCurrentView() }
          </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const { session_id, login_token, current_view } = state;
  return {
    session_id: session_id,
    login_token: login_token,
    current_view: current_view
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    getSessionAction: getSession,
    signinAction: signin
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Content));