import { 
    SET_ROLES,
    SET_MY_ROLE_MEMBERSHIP,
    SET_ACCOUNTS, 
    SET_ORGANIZATIONS,
    SET_ORGANIZATION_MEMBERS 
} from "./constants";
import { logoutAccount } from "./loginActions";

const onReceiveAccounts = (accounts) => {
    return {
        type: SET_ACCOUNTS,
        payload: accounts
    };
}

const onReceiveOrganizations = (organizations) => {
    return {
        type: SET_ORGANIZATIONS,
        payload: organizations
    };
}

const onReceiveRoles = (roles) => {
    return {
        type: SET_ROLES,
        payload: { roles: roles }
    };
}

const onReceiveOrganizationMembers = (members) => {
    return {
        type: SET_ORGANIZATION_MEMBERS,
        payload: members
    };
}

const onReceiveRoleMembership = (membership) => {
    return {
        type: SET_MY_ROLE_MEMBERSHIP,
        payload: membership
    };
}

export function loadAccounts(login_token, organization) {
    return async (dispatch) => {
        
        let org_id = organization && organization.id;
        console.log("Load accounts", login_token, organization, org_id);
        if (login_token) {
            let accounts = await fetch("/api/v1/listawsaccounts/?org_id="+org_id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': login_token["token"]
                }
            }).then(r => { 
                    if(r.ok) {
                        return r.json(); 
                    } else {
                        dispatch(logoutAccount());
                    }
                });            
            dispatch(onReceiveAccounts(accounts));         
        }
    }
}

export function awsLogin(login_token, aws_account_id, role_id, enqueueSnackbar) {
    return async (dispatch) => {
        let url = `/api/v1/awssignin/?role_id=${role_id}&account_id=${aws_account_id}`;

        let result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        });

        if (result.ok) {
            let loginUrl = await result.json();
            window.open(loginUrl["url"]);
        }
        else if(enqueueSnackbar) {
            let msg = await result.json();
            console.log(result, msg);
            enqueueSnackbar(msg.error, { variant: 'error'});        
        }
    }
}

export function saveOrganization(login_token, org) {
    return async (dispatch) => {
        let url = "/api/v1/organization";

        let result = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            },
            body: JSON.stringify(org)
        }).then(r => r.json());

        console.log("Org created", result);
        dispatch(loadMyOrganizations(login_token, org));
    }
}

export function saveRole(login_token, role) {
    return async (dispatch) => {
        let url = "/api/v1/role";

        let result = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            },
            body: JSON.stringify(role)
        }).then(r => r.json());

        dispatch(loadRoles(login_token, role.org_id));
    }
}

export function loadRoles(login_token, org_id) {
    return async (dispatch) => {
        let url = "/api/v1/role/"+org_id;

        let roles = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        }).then(r => r.json());

        dispatch(onReceiveRoles(roles));
    }
}

export function loadMyRoleMemberships(login_token) {
    return async (dispatch) => {
        let url = "/api/v1/rolemembership/";

        let roleMembership = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        }).then(r => r.json());
        
        dispatch(onReceiveRoleMembership(roleMembership));
    }
}

export function saveAccount(login_token, account) {
    return async (dispatch) => {
        let url = "/api/v1/account";

        let result = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            },
            body: JSON.stringify(account)
        }).then(r => r.json());

        console.log(result);

        dispatch(loadAccounts(login_token, {id: account.org_id}));
    }
}

export function deleteAccount(login_token, account) {
    return async (dispatch) => {
        let url = `/api/v1/account/${account.org_id}/${account.account_id}`;

        let result = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            },
            body: JSON.stringify(account)
        }).then(r => r.json());  
        
        dispatch(loadAccounts(login_token, {id: account.org_id}));
    }
}

export function deleteRole(login_token, role) {
    return async (dispatch) => {
        let url = `/api/v1/role/${role.account_id}/${role.role_id}`;

        let result = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        }).then(r => r.json());  
        
        dispatch(loadRoles(login_token, role.org_id));
    }
}

export function loadMyOrganizations(login_token) {
    return async (dispatch) => {
        let url = "/api/v1/myorganizations";

        let result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        }).then(r => r.json());

        console.log("Loaded organization with result", result);
        dispatch(onReceiveOrganizations(result));
    }
}

export function loadOrganizationMembers(login_token, org_id) {
    return async (dispatch) => {
        let url = `/api/v1/membership/${org_id}`;

        let result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        }).then(r => r.json());

        console.log("Loaded organization members with result", result);
        dispatch(onReceiveOrganizationMembers(result));
    }
}

export function submitElevationRequest(login_token, request) {
    return async (dispatch) => {
        let url = "/api/v1/elevationrequest";

        let result = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            },
            body: JSON.stringify(request)
        }).then(r => r.json());

        console.log(result);

        //dispatch(loadAccounts(login_token, {id: account.org_id}));
    }
}