import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadRoles, loadAccounts, awsLogin, deleteAccount } from "./actions/accountActions";
import NewRoleFormDialog from "./forms/newroleform";
import NewAccountFormDialog from "./forms/newaccountform";
import RolestListView from "./RolesListView";
import AccountView from "./AccountView";
import DeleteAccountDialog from "./forms/revoveaccountdlg";

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);


class AccountListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {selcted_account: undefined};
        this.selectAccount = this.selectAccount.bind(this);
        this.handleRemoveAccount = this.handleRemoveAccount.bind(this);
    }

    componentDidMount() {        
    }

    selectAccount(account) {
        console.log("Sececting account ", account);
        this.setState({selcted_account: account});
    }

    handleRemoveAccount(account) {
        this.props.deleteAccountAction(this.props.login_token, account);
    }

    render() {
        console.log(this.props.organization);
        if (!this.props.accounts) {
            return "Loading....";
        }
        else if (this.state.selcted_account) {
            return (
                <AccountView selectAccount={(a)=>{}} organization={this.props.organization} account={this.state.selcted_account} editmode={true} />
            );
        } else {
            return (
                <Grid container spacing={3}>
                    <Table>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Account name</StyledTableCell>
                                    <StyledTableCell>Role</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>#</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                                <TableBody>
                                    {Array.isArray(this.props.accounts) && 
                                        this.props.accounts.map((account) => (                                    
                                    <StyledTableRow>
                                        <TableCell>{ account.account_name }</TableCell>
                                        <TableCell>
                                            <RolestListView showpolicy={false} organization={this.props.organization} account={account} account_id={account.account_id} />
                                        </TableCell>
                                        <TableCell>
                                            <NewRoleFormDialog organization={this.props.organization} account={account} />
                                        </TableCell>
                                        <TableCell>
                                            <DeleteAccountDialog account={account} organization={this.props.organization} />
                                        </TableCell>
                                    </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>                   
                    <Grid item xs={9}>
                        <NewAccountFormDialog organization={this.props.organization} />
                    </Grid>
                </Grid>);
        }
    }
}

const mapStateToProps = (state) => {
    const { session_id, login_token, accounts, selcted_account } = state;
    return {
        session_id: session_id,
        login_token: login_token,
        accounts: accounts,
        selcted_account: selcted_account
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadAccountsAction: loadAccounts,
        loadRolesAction: loadRoles,
        awsLoginAction: awsLogin,
        deleteAccountAction: deleteAccount
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(AccountListView);
