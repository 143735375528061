import React from 'react';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadMyOrganizations } from "./actions/accountActions";
import OrganizationForm from "./forms/organization";
import AccountListView from "./AccountListView";

class OrganizationListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.loadMyOrganizationsAction(this.props.login_token);
    }

    render() {
        if (!this.props.current_organization) {
            return "Select an organization";
        }
        else {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {this.props.current_organization?(
                            <AccountListView organization={this.props.current_organization}/>
                        ):("Select an organization ...")
                        }
                    </Grid>
                </Grid>);
        }
    }
}

const mapStateToProps = (state) => {
    const { session_id, login_token, organizations, current_organization } = state;
    return {
        session_id: session_id,
        login_token: login_token,
        current_organization: current_organization
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadMyOrganizationsAction: loadMyOrganizations
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(OrganizationListView);


