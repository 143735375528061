import React from 'react';
import Grid from '@material-ui/core/Grid';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadOrganizationMembers } from "../actions/accountActions";
import { loadElevationRequests  } from "../actions/elevationActions";

import { Button } from '@material-ui/core';
import ElevationActionDialog from "../forms/elevationactiondlg";

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

class ElevationRequestsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentRequest: undefined };
        this.editRequest = this.editRequest.bind(this);
    }

    componentDidMount() {
        this.props.loadElevationRequestsAction(this.props.login_token, this.props.current_organization.id);
    }
    
    toDateString(t) {
        return (new Date(t)).toDateString();
    }

    editRequest(request) {
        this.setState({ currentRequest: request });
    }

    render() {
        if (this.props.elevation_requests && this.props.elevation_requests[this.props.current_organization.id]) {
            console.log("Elevation requests ", this.props.elevation_requests);
            return (
                <Grid container spacing={3}>                    
                        <Grid item xs={12}>
                            <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>User Id</StyledTableCell>
                                    <StyledTableCell>Account Id</StyledTableCell>
                                    <StyledTableCell>Role</StyledTableCell>
                                    <StyledTableCell>Elevation period</StyledTableCell>
                                    <StyledTableCell>#</StyledTableCell>
                                </TableRow>
                            </TableHead>                                
                                <TableBody>
                                    {Array.isArray(this.props.elevation_requests[this.props.current_organization.id]) && 
                                        this.props.elevation_requests[this.props.current_organization.id].map((request) => (                                    
                                    <TableRow>
                                        <TableCell>{ request.user_accout_id }</TableCell>
                                        <TableCell>{ request.account_id }</TableCell>
                                        <TableCell>{ request.role_name }</TableCell>
                                        <TableCell>{ request.expires_in / 1000/ 60 / 60 } Hour</TableCell>
                                        <TableCell>
                                            <ElevationActionDialog request={request} />
                                        </TableCell>
                                    </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>                    
                </Grid>);
        }
        else {
            console.log("No requests found for the org");
            return "Loading...";
        }
    }
};

const mapStateToProps = (state) => {    
    const { session_id, login_token, current_organization, elevation_requests } = state;
    console.log("elevation_requests", elevation_requests);
    return {
        session_id: session_id,
        login_token: login_token,
        current_organization: current_organization,
        elevation_requests: elevation_requests
    };
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadElevationRequestsAction: loadElevationRequests
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(ElevationRequestsView);
