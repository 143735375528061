import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';

import AccountKit from 'react-facebook-account-kit'; //TODO: security check

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
    secondaryBar: {
        zIndex: 0,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
});

function LoginViewHeader(props) {
    const { session_id, onLoginResponse, classes, onDrawerToggle, current_view, loginProfile } = props;

    const getTabLabel = () => {
        if (current_view === 'Organizations' && props.current_organization) {
            return (current_view + "/" + props.current_organization && props.current_organization["name"]);
        }
        else {
            return current_view;
        }
    };

    return (
        <React.Fragment>
            <AppBar color="primary" position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">                        
                        <Grid item xs>
                            {loginProfile && loginProfile.body ? (
                                'User Id: ' + loginProfile.body.id
                            ) : ''}
                        </Grid>
                        <Grid item>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <AppBar
                component="div"
                className={classes.secondaryBar}
                color="primary"
                position="static"
                elevation={0}
            >
                <Toolbar>
                    <Grid container alignItems="right" spacing={3}>
                        <Grid item xs={9}  alignItems="right">
                            <Typography color="inherit" variant="h5" component="h1">
                                The Ugly Duckling
                            </Typography>
                            <Typography>
                                ugly (15 August 2019) > alpha > beta > rc > swan aka release (15 December 2019)
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <React.Fragment>
                                <AccountKit
                                    appId="2376811822408007"
                                    version="v1.0" // Version must be in form v{major}.{minor}
                                    onResponse={(resp) => onLoginResponse(resp)}
                                    csrf={session_id} // Required for security
                                    countryCode={'+1'}
                                    phoneNumber={''}
                                    emailAddress={''}>
                                    {p => <Button variant="contained" color="secondary" {...p}>Login with your phone</Button>}
                                </AccountKit>
                                <p>No signup required to login for this preview release.</p>
                            </React.Fragment>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <AppBar
                component="div"
                className={classes.secondaryBar}
                color="primary"
                position="static"
                elevation={0}
            >
                <Tabs value={0} textColor="inherit">
                    <Tab textColor="inherit" label={getTabLabel()} />
                </Tabs>
            </AppBar>
        </React.Fragment>
    );
}

LoginViewHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { session_id, current_organization, current_view, loginProfile, login_token } = state;
    return {
        current_organization: current_organization,
        current_view, current_view,
        loginProfile: loginProfile,
        session_id: session_id,
        login_token: login_token
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(LoginViewHeader));
//export default withStyles(styles)(Header);