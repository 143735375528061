import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { applyMiddleware, compose, combineReducers, createStore } from 'redux';

import alertChimpAppReducer from './reducers';
import { Provider } from 'react-redux';
import thunk  from 'redux-thunk';
import { SnackbarProvider } from 'notistack';

const renderApp = (store) => {
    ReactDOM.render(
        <Provider store={store}>
            <SnackbarProvider maxSnack={3}>
                <App />
            </SnackbarProvider>
        </Provider>, 
        document.getElementById('root'));
}

const storeEnhancers = compose(
    applyMiddleware(thunk)
);

const store = createStore(
    alertChimpAppReducer,
    storeEnhancers
);

//store.dispatch({ type: "test" });

renderApp(store);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
