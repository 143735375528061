import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';

import { saveOrganizationMembership } from "../actions/organizationActions";

class MemberOfFormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: props.organization && props.organization.id,
      account_id: props.account ? props.account.account_id : '',
      expires_at: props.account? props.account.expires_at: Date.now() + (12 * 30 * 24 * 60 * 60 * 1000),
      can_approve_role: props.account? props.account.can_approve_role: false,
      can_approve_until: props.account? props.account.can_approve_until:  Date.now() + (12 * 30 * 24 * 60 * 60 * 1000),
      open: props.open
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleChange(field, value) {
    this.setState({ [field]: value });
  }

  handleSubmit(event) {
    // let org = {name: this.state.name};
    // this.props.saveOrganizationAction(this.props.login_token, org);
    console.log(this.state);
    this.props.saveOrganizationMembershipAction(this.props.login_token, this.state);
    event.preventDefault();
  }

  handleClickDelete() {
    if(this.props.account) {
      this.props.onDelete(this.props.login_token, this.props.account, this.props.enqueueSnackbar);
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Button size="small" variant="contained" color="primary" onClick={this.handleClickOpen}>
          {this.props.launchText || 'Add account'}
        </Button>
        {this.props.account && this.props.onDelete ? (
          <Button size="small" variant="contained" color="secondary" onClick={this.handleClickDelete}>
            Delete
          </Button>
        ) : ''}
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Organization membership</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              id="account_id"
              label="Account ID"
              type="text"
              value={this.state.account_id}
              onChange={(event) => this.setState({ 'account_id': event.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              id="expires_at"
              label="Expiry"
              type="date"
              defaultValue="2050-12-31"
              value={new Date(this.state.expires_at).toISOString().split('T')[0]}
              onChange={(event) => {this.setState({'expires_at': new Date(event.target.value)})}}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Checkbox
              checked={this.state.can_approve_role}
              value="can_approve_role"
              color="primary"
              inputProps={{
                'aria-label': 'can approve account or role for the organization',
              }}
              onChange={(event) => { this.setState({ 'can_approve_role': event.target.checked }) }}
            /> Can approve account or role elevation request
           <TextField
              disabled={!this.state.can_approve_role}
              fullWidth
              id="can_approve_until"
              label="Can approve until"
              type="date"
              defaultValue={new Date(Date.now() + (30 * 24 * 60 * 60 * 1000)).toISOString().split('T')[0]}  
              value={new Date(this.state.can_approve_until).toISOString().split('T')[0]}            
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
          </Button>
            <Button onClick={this.handleSubmit} variant="contained" color="primary">
              Submit
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { session_id, login_token } = state;
  return {
    session_id: session_id,
    login_token: login_token
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    saveOrganizationMembershipAction: saveOrganizationMembership
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(MemberOfFormDialog));