import React from 'react';
import Typography from '@material-ui/core/Typography';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withSnackbar } from 'notistack';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import DeleteRoleDialog from './forms/removeroledlg';

import { loadRoles, awsLogin, loadMyRoleMemberships, submitElevationRequest, deleteRole } from "./actions/accountActions";

class RolestListView extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            selected_role: undefined, 
            selected: undefined,
            elevationData: {},
            openRoleMenu: {}

        };

        this.handleChange = this.handleChange.bind(this);
        this.resuestElevation = this.resuestElevation.bind(this);
        this.handleElevationRequestClose = this.handleElevationRequestClose.bind(this);
        this.handleElevationRequestSubmit = this.handleElevationRequestSubmit.bind(this);
        this.handleRoleActions = this.handleRoleActions.bind(this);
        this.handleRoleMenuClose = this.handleRoleMenuClose.bind(this);
        this.handleRoleMenuDeleteAction = this.handleRoleMenuDeleteAction.bind(this);
        this.handleRoleMenuShowPolicy = this.handleRoleMenuShowPolicy.bind(this);
        this.updateClipboard = this.updateClipboard.bind(this);
        this.getTrustRelationship = this.getTrustRelationship.bind(this);
        this.classes = {};

        this.anchorRef = null;
    }

    getTrustRelationship(role) {
        let alerchimpExecutionRole = this.props.alerchimpExecutionRole;        
        let relationShip = {
            "Version": "2012-10-17",
            "Statement": [
              {
                "Effect": "Allow",
                "Principal": {
                  "AWS": alerchimpExecutionRole
                },
                "Action": "sts:AssumeRole",
                "Condition": {
                  "StringEquals": {
                    "sts:ExternalId": this.props.organization.id
                  }
                }
              }
            ]
          };
        return relationShip;
    }
    
    componentDidMount() {
        if(this.props.organization && this.props.account_id) {
            this.props.loadRolesAction(this.props.login_token, this.props.organization.id, this.props.account_id);
        }
        else {
            console.log(this.props);
        }

        if(!this.props.role_membership_map) {
            this.props.loadMyRoleMembershipsAction(this.props.login_token);
        }
    }

    handleChange(role, event, isExpanded) {
        console.log("is expanded", isExpanded)
        this.setState({selected_role: isExpanded?role: undefined});
    }

    resuestElevation(role) {
        this.setState({elevationData: {role: role, expires_in: 60*60*1000}});
    }

    handleElevationRequestSubmit() {
        let request = {
            account_id: this.state.elevationData.role.account_id,
            role_id: this.state.elevationData.role.role_id,
            expires_in: this.state.elevationData.expires_in
        };
        console.log("Elevation request", request);
        this.props.submitElevationRequestAction(this.props.login_token, request);
        this.handleElevationRequestClose();        
    }

    handleElevationRequestClose() {
        this.setState({elevationData: {}});
    }

    handleRoleActions(role) {
        this.setState({openRoleMenu: {[role.role_arn]: true}});
    }

    handleRoleMenuClose() {
        this.setState({openRoleMenu: {}});
    }

    updateClipboard(newClip, msg, errMsg) {
        console.log(newClip);
        let enqueueSnackbar = this.props.enqueueSnackbar;
        navigator.clipboard.writeText(newClip).then(function() {
          /* clipboard successfully set */
          enqueueSnackbar(msg || "Item has been copied to clipboard.", { variant: 'success'});
        }, function() {
          /* clipboard write failed */          
          enqueueSnackbar(errMsg || 'Could not copy item to clipboard. Please check console window for the item.', { variant: 'warning'});
        });
      }

    handleRoleMenuShowPolicy(role) {
        this.setState({openRoleMenu: {}});
        let policy = this.getTrustRelationship(role);

        this.updateClipboard(JSON.stringify(policy, null, 4));
    }

    handleRoleMenuDeleteAction(role) {
        if(!role) {
            return;
        }
        //this.props.deleteRoleAction(this.props.login_token, role);
        this.selectedRoleToDelete = role;
        this.setState({openRoleMenu: {}});
    }

    render() {
        let roles = this.props.account_roles_map?this.props.account_roles_map[this.props.account_id]: [];        
        if(roles) {
            //TODO: check how it behaves with update of roles
            if(!this.anchorRef) {
                this.anchorRef = [];
                for(let i=0;i<roles.length;i++) {
                    let role = roles[i];
                    this.anchorRef[role.role_arn] = React.createRef();
                }
            }
    
            if(this.props.showpolicy) {
                return (
                    <div>                        
                        {roles.map(role => (
                            <Grid item xs={12}>
                                <ExpansionPanel expanded={this.state.selected_role === role} onChange={(event, isExpanded) => this.handleChange(role, event, isExpanded)}>
                                    <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header">
                                    <Typography key={role.role_arn} size="small">{role.role_name} </Typography>
                                    <Typography key={role.role_arn} size="small">{role.role_arn}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    
                                    <textarea rows={18} cols={80} readonly>
                                        {JSON.stringify({
                                        "Version": "2012-10-17",
                                        "Statement": [
                                            {
                                            "Effect": "Allow",
                                            "Principal": {
                                                "AWS": "arn:aws:iam::688944735181:root"
                                            },
                                            "Action": "sts:AssumeRole",
                                            "Condition": {
                                                "StringEquals": {
                                                "sts:ExternalId": this.props.organization.id
                                                }
                                            }
                                            }
                                        ]
                                        }, undefined, 4)} 
                                    </textarea>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>

                        ))}
                    </div>
                );
            }
            else {
                return (
                    <React.Fragment>
                        {roles.map(role => (                            
                             <React.Fragment>
                                    {role.auto_approved || (this.props.role_membership_map && this.props.role_membership_map[role.role_id])? (
                                        <React.Fragment>
                                            <ButtonGroup variant="contained" color="primary" aria-label="split button">
                                                <Button key={role.role_arn} variant="contained" color="primary" size="small"
                                                    onClick={() => this.props.awsLoginAction(this.props.login_token, role.account_id, role.role_id, this.props.enqueueSnackbar)}>{role.role_name}
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"                                            
                                                    aria-haspopup="true"
                                                    ref={this.anchorRef[role.role_arn]}
                                                    onClick={() => this.handleRoleActions(role)}>
                                                    <ArrowDropDownIcon onClick={() => this.handleRoleActions(role)}/>
                                                </Button>
                                            </ButtonGroup>

                                            {this.selectedRoleToDelete === role ?(
                                            <DeleteRoleDialog role={role} organization={this.props.organization} account={this.props.account}
                                                open={role===this.selectedRoleToDelete} onClose={()=>{this.selectedRoleToDelete = undefined}} />
                                            ):''}

                                            <Menu anchorEl={this.anchorRef[role.role_arn] && this.anchorRef[role.role_arn].current} 
                                                open={role && this.state.openRoleMenu[role.role_arn]} keepMounted 
                                                onClose={this.handleRoleMenuClose}>
                                                <MenuItem onClick={()=> this.handleRoleMenuShowPolicy(role)}>Copy policy</MenuItem>
                                                <MenuItem onClick={() => { this.handleRoleMenuDeleteAction(role) }}>Delete role</MenuItem>
                                            </Menu>            
                                        </React.Fragment>                                        
                                    ):(
                                        <React.Fragment>
                                            <ButtonGroup variant="outlined" color="primary" aria-label="split button">
                                                <Button  variant="outlined" key={role.role_arn} color="primary" size="small"
                                                onClick={() => this.resuestElevation(role)}>{role.role_name}                                        
                                                </Button>                                      
                                                <Button
                                                    color="primary"
                                                    variant="outlined"
                                                    size="small"                                            
                                                    aria-haspopup="true"
                                                    onClick={() => this.handleRoleActions(role)}
                                                    ref={this.anchorRef[role.role_arn]}
                                                    >
                                                    <ArrowDropDownIcon onClick={() => this.handleRoleActions(role)} />
                                                </Button>  
                                            </ButtonGroup>

                                            {this.selectedRoleToDelete === role ?(
                                            <DeleteRoleDialog role={role} organization={this.props.organization} account={this.props.account}
                                                open={role===this.selectedRoleToDelete} onClose={()=>{this.selectedRoleToDelete = undefined}} />
                                            ):''}

                                            <Menu anchorEl={this.anchorRef[role.role_arn] && this.anchorRef[role.role_arn].current} 
                                                open={role && this.state.openRoleMenu[role.role_arn]} keepMounted transition
                                                onClose={this.handleRoleMenuClose}>
                                                <MenuItem onClick={this.handleRoleMenuShowPolicy}>Copy policy</MenuItem>
                                                <MenuItem onClick={() => { this.handleRoleMenuDeleteAction(role) }}>Delete role</MenuItem>
                                            </Menu>            
                                        </React.Fragment>
                                    )}
                                    <Dialog open={this.state.elevationData && this.state.elevationData.role} onClose={this.handleClose} fullWidth={true} maxWidth="xs" aria-labelledby="form-dialog-title">
                                        <DialogTitle id="form-dialog-title">Role elevation request</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>            
                                                <Typography gutterBottom>
                                                    <b>Role:</b>{this.state.elevationData.role && this.state.elevationData.role.role_name}
                                                </Typography>
                                            </DialogContentText>
                                            
                                            <FormControl>
                                                <InputLabel htmlFor="age-native-helper">Elevation period</InputLabel>
                                                <NativeSelect
                                                    value={this.state.elevationData.expires_in}
                                                    onChange={(event) => {this.setState({elevationData: Object.assign({}, this.state.elevationData, {expires_in: event.target.value}) })}}
                                                    input={<Input name="expires_in" id="expires_in-native-helper" />}
                                                >
                                                <option value={60*60*1000}>1 Hour</option>
                                                <option value={2*60*60*1000}>2 Hours</option>
                                                <option value={4*60*60*1000}>4 Hours</option>
                                                </NativeSelect>
                                                <FormHelperText>How long should you stay elevated?</FormHelperText>
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleElevationRequestClose} color="primary">
                                            Cancel
                                        </Button>
                                            <Button onClick={this.handleElevationRequestSubmit} variant="contained" color="primary">
                                            Request Elevation
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                );
            }
        }
        else if(!this.props.account_roles_map) {
            return (
                <div>
                   <CircularProgress />
                </div>);
        }
        else {
            return (                
                <div>
                    No role found for this account                    
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    const { session_id, login_token, account_roles_map , role_membership_map, alerchimpExecutionRole} = state;
    return {
        session_id: session_id,
        login_token: login_token,
        account_roles_map: account_roles_map,
        role_membership_map: role_membership_map,
        alerchimpExecutionRole: alerchimpExecutionRole
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadRolesAction: loadRoles,
        awsLoginAction: awsLogin,
        loadMyRoleMembershipsAction: loadMyRoleMemberships,
        submitElevationRequestAction: submitElevationRequest,
        deleteRoleAction: deleteRole
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(RolestListView));