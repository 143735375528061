import React from 'react';

import { withStyles } from '@material-ui/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';

import { blue, red } from '@material-ui/core/colors';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadMyOrganizations } from "../actions/accountActions";
import { loadRoles, loadAccounts, awsLogin } from "../actions/accountActions";
import OrganizationForm from "../forms/organization";
import OrganizationListView from "../OrganizationListView";
import { changeNavigation } from "../actions/navigationActions";
import { changeCurrentOrganization } from "../actions/organizationActions";

import DeleteOrganizationDialog from '../forms/deleteorgdlg';

const styles = theme => ({
    card: {
      maxWidth: 290,
      margin: 8
    },
    media: {
      height: 140,
      margin:12
    },
    icon: {
        margin: theme.spacing(2),
      },
      iconHover: {
        margin: theme.spacing(2),
        '&:hover': {
          color: red[800],
        },
      },
  });

class HomeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOrgToDelete: null,
            open: false
        };

        this.handleDeleteOrganization = this.handleDeleteOrganization.bind(this);
    }

    componentDidMount() {
        console.log("HomeView mounted");
        this.props.loadMyOrganizationsAction(this.props.login_token);
    }

    openOrganization(org) {
        this.props.loadAccountsAction(this.props.login_token, org); 
        this.props.changeCurrentOrganizationAction(org);
        this.props.changeNavigationAction("Organizations");
    }

    handleDeleteOrganization(org) {
        console.log(org);
        this.setState({selectedOrgToDelete: org}, () => {
            console.log(this.state.selectedOrgToDelete);
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>            
            {this.props.organizations && this.props.organizations.map((org) => (
                <React.Fragment key={org.id}>
                <Card className={classes.card}>
                    <CardHeader
                        action={
                        <IconButton aria-label="settings">
                            <DeleteForeverIcon  onClick={() =>{ this.handleDeleteOrganization(org)}} />
                            {this.state.selectedOrgToDelete?(
                                <DeleteOrganizationDialog organization={org} open={this.state.selectedOrgToDelete === org}
                                    onClose={()=>this.handleDeleteOrganization(null)}
                                />
                            ):''}
                        </IconButton>                        
                        }
                        title={org.name}
                    />
                    <CardActionArea onClick={()=> this.openOrganization(org)}>
                        <CardMedia
                        className={classes.media}
                        image="/images/org.svg"
                        title="Organization"
                        />
                        <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {org.id}
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                </React.Fragment>
              ))}
              <Grid item xs={12}>
                <OrganizationForm />              
              </Grid>
            </Grid>);
    }
}

const mapStateToProps = (state) => {
    const { session_id, login_token, organizations } = state;
    return {
        session_id: session_id,
        login_token: login_token,
        organizations: organizations
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadMyOrganizationsAction: loadMyOrganizations,
        changeNavigationAction: changeNavigation,
        changeCurrentOrganizationAction: changeCurrentOrganization,
        loadAccountsAction: loadAccounts
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(HomeView));
