
import {
    CHANGE_CURRENT_VIEW
} from "./constants";


const onChangeNavigationView = (pageId) => {
    return {
        type: CHANGE_CURRENT_VIEW,
        payload: pageId
    };
}

export const changeNavigation = (pageId) => {
    return dispatch => {
        console.log("Changed page to ", pageId);
        dispatch(onChangeNavigationView(pageId));
    }
}
