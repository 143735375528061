import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { changeNavigation } from "./actions/navigationActions";
import { changeCurrentOrganization } from "./actions/organizationActions";
import { loadAccounts } from "./actions/accountActions";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, current_view, changeCurrentOrganizationAction, loadAccountsAction, changeNavigationAction, ...other } = props;
  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          alertchimp
        </ListItem>
        <ListItem button className={clsx(classes.item, classes.itemCategory)} onClick={()=> { changeNavigationAction('Home'); }}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Home
          </ListItemText>
        </ListItem>              
        { props.current_view !== 'Home' && props.current_view !== 'About'  && props.current_view ? (
          <ListItem button className={clsx(classes.item, classes.itemCategory,  current_view === 'Organizations' && classes.itemActiveItem)} onClick={()=> { changeNavigationAction('Organizations'); }}>
          <ListItemIcon className={classes.itemIcon}>
            <Icon>domain</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            Accounts
          </ListItemText>
        </ListItem> 
        ):''}  
        { props.current_view !== 'Home' && props.current_view !== 'About' && props.current_organization && props.navigationItems.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active }) => (
              <ListItem onClick={()=> { changeNavigationAction(childId); }}
                key={childId}
                button
                className={clsx(classes.item, current_view === childId && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
        <ListItem button className={clsx(classes.item, classes.itemCategory)} onClick={()=> { changeNavigationAction('About'); }}>
          <ListItemIcon className={classes.itemIcon}>
            <Icon>help_outline</Icon>
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
          >
            About
          </ListItemText>
        </ListItem>        
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { session_id, login_token, navigationItems, organizations, current_view, current_organization } = state;
  return {
    session_id: session_id,
    login_token: login_token,
    current_view: current_view,
    navigationItems: navigationItems,
    organizations: organizations,
    current_organization: current_organization
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    changeNavigationAction: changeNavigation,
    changeCurrentOrganizationAction: changeCurrentOrganization,
    loadAccountsAction: loadAccounts
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(Navigator));

//export default withStyles(styles)(Navigator);