import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { deleteRole } from "../actions/accountActions";

class DeleteRoleDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleSubmit(event) {
    this.props.deleteRoleAction(this.props.login_token, this.props.role);    
    this.handleClose();
  }

  handleClose() {
      console.log("Closing delete role confirmation dialog");      
      this.setState({open:false});
      if(this.props.onClose) {
        this.props.onClose();
      }
  }

  render() {
    return (
        <Dialog open={this.state.open} onClose={this.handleClose} fullWidth={true} maxWidth="sm" aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Warning</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography gutterBottom>
                The role and all associated resources will be removed from the system. This action is permanent.  
              </Typography>            
              <Typography gutterBottom>
                <b>Organization:</b> {this.props.organization.name}
              </Typography>
              <Typography gutterBottom>
                <b>Account:</b> { this.props.account.account_name } ({this.props.account.account_id})
              </Typography>
              <Typography gutterBottom>
                <b>Role:</b> { this.props.role.role_name } ({this.props.role.role_arn})
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" variant="contained">
              Cancel
          </Button>
            <Button onClick={this.handleSubmit} color="secondary" variant="contained">
              Delete role
          </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { session_id, login_token } = state;
  return {
    session_id: session_id,
    login_token: login_token
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    deleteRoleAction: deleteRole
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(DeleteRoleDialog);