import React from 'react';

import { withStyles } from '@material-ui/styles';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadMyOrganizations } from "../actions/accountActions";
import { loadRoles, loadAccounts, awsLogin } from "../actions/accountActions";
import OrganizationForm from "../forms/organization";
import OrganizationListView from "../OrganizationListView";
import { changeNavigation } from "../actions/navigationActions";
import { changeCurrentOrganization } from "../actions/organizationActions";

const styles = theme => ({
    card: {
      maxWidth: 290,
      margin: 8
    },
    media: {
      height: 140,
      margin:12
    },
  });

class SettingsView extends React.Component {
    constructor(props) {
        super(props);        
    }

    componentDidMount() {
        console.log("HomeView mounted");
        this.props.loadMyOrganizationsAction(this.props.login_token);
    }

    openOrganization(org) {
        this.props.loadAccountsAction(this.props.login_token, org); 
        this.props.changeCurrentOrganizationAction(org);
        this.props.changeNavigationAction("Organizations");
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={3}>
                Will add some settings for multifactor authentication (including hardware token), default behaviour etc. in this page.
            </Grid>);
    }
}

const mapStateToProps = (state) => {
    const { session_id, login_token, organizations } = state;
    return {
        session_id: session_id,
        login_token: login_token,
        organizations: organizations
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadMyOrganizationsAction: loadMyOrganizations,
        changeNavigationAction: changeNavigation,
        changeCurrentOrganizationAction: changeCurrentOrganization,
        loadAccountsAction: loadAccounts
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(SettingsView));
