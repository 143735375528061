import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadRoles, loadAccounts, awsLogin } from "./actions/accountActions";
import NewRoleFormDialog from "./forms/newroleform";
import RolestListView from "./RolesListView";

class AccountView extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        if (!this.props.account) {
            return ":[";
        }
        else {
            return (
                <Grid container spacing={3}>                    
                    <RolestListView showpolicy={!!this.props.editmode} organization={this.props.organization} account_id={this.props.account.account_id} />
                    <Button size="small" onClick={()=>{ this.props.selectAccount(!this.props.editmode && this.props.account); }}>
                           Edit {this.props.account.account_name}
                    </Button>                    
                    
                    <Grid item xs={12}>
                        {this.props.editmode?(
                        <NewRoleFormDialog organization={this.props.organization} account={this.props.account} />                    
                        ):''}
                    </Grid>
                </Grid>);
        }
    }
}

const mapStateToProps = (state) => {
    const { session_id, login_token, accounts } = state;
    return {
        session_id: session_id,
        login_token: login_token,
        accounts: accounts
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        awsLoginAction: awsLogin
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(AccountView);
