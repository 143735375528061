import React from 'react';

import { withStyles } from '@material-ui/styles';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const styles = theme => ({
    page: {
        padding: theme.spacing(3, 2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
  });

class AboutView extends React.Component {
    constructor(props) {
        super(props);        
    }

    componentDidMount() {
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
            <Paper className={classes.page}>
                <Typography variant="h5" component="h3" align={'left'}>                    
                    What problem does it solve?
                </Typography>
                <Typography component="p" align={'left'}>
                    For security reasons, administrators should not create permanent user accounts for users of cloudservices (except root user which should 
                    be carefully guarded and used very rarely). Access should be granted through an automated role elevation that goes 
                    through an optional approval by one or more persons. Alerchimp help achieving this without an in-house developed 
                    service. Alerchimp's own actions can be logged and audited which AlertChimp service can not temper.
                </Typography>                                
                <Typography component="p" align={'left'}>
                    Please refer to following AWS best practice document for more information: 
                    <a href="https://docs.aws.amazon.com/general/latest/gr/aws-access-keys-best-practices.html#use-roles">https://docs.aws.amazon.com/general/latest/gr/aws-access-keys-best-practices.html#use-roles</a>. 
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h5" component="h3" align={'left'}>                    
                    Is it possible to use own infrastructure?
                </Typography>
                <Typography component="p" align={'left'}>
                    Yes, critical part of Alertchimp can also be deployed in customers own infrastructure for complete control over the assumerole workflow.
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h5" component="h3" align={'left'}>                    
                    Is there any alternative?
                </Typography>                
                <Typography component="p" align={'left'}>
                    You could use one of our competitors product for the same purpose (not linking any because we can not endorse third party software here, please use search).
                    If you want to build something in-house like AlertChimp please revisit this site for a blog post about how to do it after our production release. 
                    We support deploying AlertChimp in your own account/infrastructure as well.
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h5" component="h3" align={'left'}>How AlertChimp helps?</Typography>
                <Typography component="p" align={'left'}>
                    AlertChimp manages organization of users of AWS and creates login session for limited time.

                    It allows to log and audit each login.

                    It can add approval workflow for users - bob wants to use the cloud service as power user. 
                    He does not have that permission by default. He requests for an elevation of privilege for fixed time like 4 hours and the request goes 
                    to Alice and when she approves bobs account gets an elevation for 4 hours. He can login to with that role for 4 hours and then the 
                    privilege is revoked automatically.

                    It is possible to create Alerts based on request and logins. Alerts are delivered to phone or app.
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h5" component="h3" align={'left'}>What is the current development stage?</Typography>
                <Typography component="p" align={'left'}>
                    At this moment we call this ugly stage (as in the ugly duckling).
                    This was built our own AWS accounts and we have just decided that it is a good tool and we can share with others. But at the same time 
                    we realize that we need to ask people to actually know if it will be useful if we productize it. We do not want to spend time on something 
                    that is not very useful. But we hope this will be useful of course.
                    We had the entire thing built by logging in to the AWS console directly. As part of showing this to you, we 
                    have made cloudformation template 
                    so that entire project can be deployed to any AWS account with a single deployment command. We have one more module that we have not integrated 
                    with this project yet. It is the on-call manager module. We plan to add it sometimes during beta stage.
                </Typography>
                <Divider className={classes.divider} />
                <Typography variant="h5" component="h3" align={'left'}>When will we be able to use it in production?</Typography>
                <Typography component="p" align={'left'}>
                    This should be useful during beta phase. But we plan to make a release in December 2019 (when the ugly duckling becomes a swan).
                </Typography>
            </Paper>
            </div>
            );
    }
}

const mapStateToProps = (state) => {
    const { session_id, login_token, organizations } = state;
    return {
        session_id: session_id,
        login_token: login_token,
        organizations: organizations
    };
}

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(AboutView));
