import { 
    SET_PENDING_ELEVATION_REQUESTS,
} from "./constants";

const onReceiveElevationRequests = (org_id, requests) => {
    return {
        type: SET_PENDING_ELEVATION_REQUESTS,
        payload: {org_id: org_id, requests: requests}
    };
}


export function loadElevationRequests(login_token, org_id) {
    return async (dispatch) => {
        let url = `/api/v1/elevationrequests/${org_id}/`;

        let result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        }).then(r => r.json());

        console.log(result);

        dispatch(onReceiveElevationRequests(org_id, result));
    }
}

export function approveElevationRequest(login_token, request) {
    return async (dispatch) => {
        let url = `/api/v1/elevationrequests/${request.request_id}/approve/`;

        let result = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        }).then(r => r.json());

        console.log(result);
        dispatch(loadElevationRequests(login_token, request.org_id));
    }
}

export function denyElevationRequest(login_token, request) {
    return async (dispatch) => {
        let url = `/api/v1/elevationrequests/${request.request_id}/deny/`;

        let result = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': login_token["token"]
            }
        }).then(r => r.json());

        console.log(result);
        dispatch(loadElevationRequests(login_token, request.org_id));
    }
}