import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import {
    SET_SESSION_ID,
    SET_LOGIN_TOKEN,
    SET_ACCOUNTS,
    SET_LOGIN_PROFILE,
    LOGOUT_ACCOUNT,
    CHANGE_CURRENT_VIEW,
    SET_ORGANIZATIONS,
    CHANGE_CURRENT_ORGANIZATION,
    SET_ROLES,
    SET_MY_ROLE_MEMBERSHIP,
    SET_ORGANIZATION_MEMBERS,
    SET_PENDING_ELEVATION_REQUESTS
} from "../actions/constants";

const initialState = {

    navigationItems: [
        {
            id: 'Administration',
            children: [
                { id: 'Users', icon: <AccountBoxIcon /> },
                { id: 'Requests', icon: <TimerIcon /> },
                { id: 'Settings', icon: <SettingsIcon /> }                
            ],
        },
    ],
    alerchimpExecutionRole: "arn:aws:iam::614730065075:root"
};

let init = false;

const loadToken = () => {

    let login_token_str = sessionStorage.getItem("login_token");
    if (login_token_str) {
        console.log("Login token found ", login_token_str);
        let login_token = JSON.parse(login_token_str);
        let exp = login_token["exp"];
        var valid = exp > Math.floor((new Date()).getTime() / 1000);
        console.log(valid, exp, Math.floor((new Date()).getTime() / 1000))
        if (valid) {
            return login_token;
        }
    }
    else {
        console.log("Could not find login token in session storage");
    }

    return undefined;
}

const prepareRoleMap = (roles) => {
    if(!Array.isArray(roles)) {
        return {};
    }

    let map = {};
    console.log("Create map from roles", roles);    
    roles.forEach(role => {
        if (!map[role.account_id]) {
            map[role.account_id] = [];
        }

        map[role.account_id].push(role);
    });

    return map;
}

const prepareRoleMembershipMap = (roleMemberships) => {
    let map = {};
    console.log("Create map from rolemembership ", roleMemberships);

    roleMemberships.forEach(roleMembership => {
        map[roleMembership.role_id] = roleMembership;
    });

    return map;
}

export default (state = initialState, action) => {
    console.log(action);

    if(!init) {
        let login_token = loadToken();
        let session_id = sessionStorage.getItem("session_id");
        let current_view = undefined; //sessionStorage.getItem("current_view");
        let current_organization = undefined; // sessionStorage.getItem("current_organization");
        let initState = {
            session_id: session_id,
            login_token: login_token,
            current_view: current_view,
            current_organization: current_organization,
            account_roles_map: {}                
        };
        console.log(initState);
        init = true;
        return Object.assign({}, state, initState);
    }

    switch (action.type) {
        case SET_SESSION_ID:
            var newState = Object.assign({}, state, { session_id: action.payload });
            console.log("SET_SESSION_ID", newState)
            return newState;
        case SET_LOGIN_TOKEN:
            return Object.assign({}, state, { login_token: action.payload });
        case SET_ACCOUNTS:
            return Object.assign({}, state, { accounts: action.payload });
        case LOGOUT_ACCOUNT:
            return Object.assign({}, state, { login_token: undefined });
        case CHANGE_CURRENT_VIEW:
            sessionStorage.setItem("current_view", action.payload);
            return Object.assign({}, state, { current_view: action.payload });
        case SET_ORGANIZATIONS:
            return Object.assign({}, state, { organizations: action.payload });
        case SET_ROLES:
            console.log("Call create map", action.payload);
            let map = prepareRoleMap(action.payload.roles);
            if (map) {
                return Object.assign({}, state, { account_roles_map: map })
            }
            break;
        case SET_MY_ROLE_MEMBERSHIP:
            let rmmap = prepareRoleMembershipMap(action.payload);
            if (rmmap) {
                return Object.assign({}, state, { role_membership_map: rmmap })
            }
            break;
        case CHANGE_CURRENT_ORGANIZATION:
            sessionStorage.setItem("current_organization", action.payload);    
            return Object.assign({}, state, { selcted_account: undefined, current_organization: action.payload })
        case SET_ORGANIZATION_MEMBERS:
            return Object.assign({}, state, { current_organization_members: action.payload });
        case SET_PENDING_ELEVATION_REQUESTS:
            let elevationRequests = {};
            if(state.elevation_requests) {
                elevationRequests = JSON.parse(JSON.stringify(state.elevation_requests));
            }            
            elevationRequests[action.payload.org_id] = action.payload.requests;
            return Object.assign({}, state, { elevation_requests: elevationRequests });
        case SET_LOGIN_PROFILE:
            return Object.assign({}, state, { loginProfile: action.payload });
        default:
            return state;
    }

    return state;
}

