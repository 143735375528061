import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { saveAccount } from "../actions/accountActions";

class NewAccountFormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      org_id: props.organization && props.organization.id,
      open: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleChange(field, value) {
    this.setState({ [field]: value });
  }

  handleSubmit(event) {
    console.log(this.state);
    this.props.saveAccountAction(this.props.login_token, this.state);
    event.preventDefault();
    this.setState({ open: false });
  }

  handleClose() {
    this.setState({ open: false });
  }

  render() {
    return (
      <div>
        <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
          Add account
        </Button>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">New account</DialogTitle>
          <DialogContent>
            <DialogContentText>            
                <Typography gutterBottom>
                  <b>Organization:</b> {this.props.organization.name}
                </Typography>
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="account_id"
                label="AWS Account ID"
                type="text"
                fullWidth
                value={this.state.account_id}
                onChange={(event) => this.handleChange("account_id", event.target.value)}
              />
              <TextField                
                margin="dense"
                id="account_name"
                label="Account name"
                type="text"
                fullWidth
                onChange={(event) => this.handleChange("account_name", event.target.value)}
              />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
          </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Submit
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { session_id, login_token } = state;
  return {
    session_id: session_id,
    login_token: login_token
  };
}

const mapActionsToProps = (dispatch, props) => {
  return bindActionCreators({
    saveAccountAction: saveAccount
  }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(NewAccountFormDialog);