import React from 'react';
import Grid from '@material-ui/core/Grid';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loadOrganizationMembers } from "../actions/accountActions";
import { removeOrganizationMembership  } from "../actions/organizationActions";

import { Button } from '@material-ui/core';
import MemberOfFormDialog from "../forms/memberofform";

const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

class OrganizationMembershipView extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentAccount: undefined };
    }

    componentDidMount() {
        this.props.loadOrganizationMembersAction(this.props.login_token, this.props.current_organization.id);
    }
    
    toDateString(t) {
        return (new Date(t)).toDateString();
    }

    editAccount(account) {
        this.setState({ currentAccount: account });
    }

    render() {
        if (!this.props.current_organization_members) {
            return "";
        }
        else {
            return (
                <Grid container spacing={3}>                    
                        <Grid item xs={12}>
                            <Table>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>Account Id</StyledTableCell>
                                    <StyledTableCell>Expiry</StyledTableCell>
                                    <StyledTableCell>Approval Expiry</StyledTableCell>
                                    <StyledTableCell>#</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>                                
                                <TableBody>
                                    {Array.isArray(this.props.current_organization_members) && 
                                        this.props.current_organization_members.map((account) => (                                    
                                    <StyledTableRow>
                                        <TableCell>
                                            <AccountBoxIcon />
                                        </TableCell>
                                        <TableCell>{ account.account_id }</TableCell>
                                        <TableCell>{ this.toDateString(account.expires_at) }</TableCell>
                                        <TableCell>{ account.can_approve_until>0? this.toDateString(account.can_approve_until): "" }</TableCell>
                                        <TableCell>
                                            <MemberOfFormDialog onDelete={this.props.removeOrganizationMembershipAction} account={account} organization={this.props.current_organization} launchText={"Edit"}  />
                                        </TableCell>
                                    </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <MemberOfFormDialog organization={this.props.current_organization} />
                        </Grid>                    
                </Grid>);
        }
    }
};

const mapStateToProps = (state) => {
    const { session_id, login_token, current_organization, current_organization_members } = state;
    return {
        session_id: session_id,
        login_token: login_token,
        current_organization: current_organization,
        current_organization_members: current_organization_members
    };
};

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        loadOrganizationMembersAction: loadOrganizationMembers,
        removeOrganizationMembershipAction: removeOrganizationMembership
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(OrganizationMembershipView);
